import {
  useGtm,
} from '@gtm-support/vue-gtm';
import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosRequestConfig,
} from 'axios';
import dayjs from 'dayjs';
import {
  getAppInfo,
} from 'utilities';
import {
  CONSTANTS,
} from './constants';
import Bus, {
  NOTIFICATION,
} from '@/bus';
import {
  baseServerUrl,
} from '@/configs/client.config.json';
import {
  IDevice,
  ISearchParams,
  IUser,
} from '@/types';

export * from './constants';

export const {
  SERVER_PORT,
  CLIENT_PORT,
  IS_DEVELOPMENT,
  CONFIG,
  ROUTE_API,
  VIEW_API,
} = getAppInfo();

const { protocol, hostname } = window.location;

// for local testing, baseServerUrl = https://localhost:3001 can be set, if necessary
export const baseUrl = baseServerUrl || (IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${SERVER_PORT}`
  : window.location.origin);

export const clientBaseUrl = IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${CLIENT_PORT}`
  : window.location.origin;

export function isYouTubeUrl(url: string): boolean {
  return /https?:\/\/(www\.)?youtu\.?be/.test(url);
}

export const isUUIDv4 = (inputString: string): boolean => {
  const uuidv4Pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidv4Pattern.test(inputString);
};

export const isEmailValid = (email: string): boolean => /\S+@\S+\.\S+/.test(email);

export const sleep = (ms: number): Promise<unknown> => new Promise((r) => {
  setTimeout(r, ms);
});

export function ordinalize(n: number): string {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function abbreviateName(firstName?: string, lastName?: string): string {
  if (firstName && lastName) {
    return (firstName[0] + lastName[0]).toUpperCase();
  }
  return (firstName || lastName)?.toUpperCase() || 'N/A';
}

export function viewportBreakpoint(): string {
  const width = window.innerWidth;
  let breakpoint = 'xs';

  if (width >= 640) {
    breakpoint = 'sm';
  }
  if (width >= 768) {
    breakpoint = 'md';
  }
  if (width >= 1024) {
    breakpoint = 'lg';
  }
  if (width >= 1280) {
    breakpoint = 'xl';
  }
  if (width >= 1536) {
    breakpoint = '2xl';
  }
  return breakpoint;
}

export async function profileImageSrc(user?: IUser): Promise<string> {
  // if there is no profile image
  if (!user?.profileImage) {
    return '';
  }

  const { profileImage } = user;

  // if profileImage is stored in server
  if (!profileImage.startsWith('http')) {
    return getFileSrc(profileImage);
  }

  // if profile image is remote url

  const fbId = new URL(profileImage).searchParams.get('asid');

  // if profile image comes from fb
  if (fbId) {
    const response = await fetch(profileImage);
    // if current fb profile image is present return it
    if (response.status === 200) {
      return profileImage;
    }
    // returns default fb profile image
    return `https://graph.facebook.com/${fbId}/picture?type=normal`;
  }
  // if profileImage is remote url but not from fb
  return profileImage;
}

export function getFileSrc(
  fileName: string,
  local = false,
  url = false,
): string {
  if (
    !fileName.length
      || fileName.startsWith('http')
      || fileName.startsWith('data:')
  ) {
    return fileName;
  }

  let fullPath: string;
  if (fileName.includes('/')) {
    // Split and encode only if there's a directory path
    const pathParts = fileName.split('/');
    const encodedFileName = encodeURIComponent(pathParts.pop() || '');
    const directoryPath = pathParts.join('/');
    fullPath = `${directoryPath}/${encodedFileName}`;
  } else {
    // Encode the file name directly if there's no directory
    fullPath = encodeURIComponent(fileName);
  }

  // Construct the URL parameters with the full path
  const params = new URLSearchParams({
    fileName: fullPath,
    ...(local && {
      local: '1',
    }),
    ...(url && {
      url: '1',
    }),
  });

  const fileUrl = `${baseUrl}${ROUTE_API}/files?${params.toString()}`;
  return fileUrl;
}

export function durationTime(val: number): string {
  if (!val) {
    return '';
  }
  return val >= 3600
    ? new Date(val * 1000).toISOString().substr(11, 8)
    : new Date(val * 1000).toISOString().substr(14, 5);
}

export function handleAxiosError<T extends {
  message: string;
  errors?: Map<string, string> | Record<string, string | undefined>;
}>(e: AxiosError<T>, defaultMessage = 'দুঃখিত, একটি সমস্যা হয়েছে', showAdditionalError = false): void {
  console.error(e);

  if (!e.isAxiosError || !e?.response?.data) {
    Bus.emit(NOTIFICATION.ERROR, {
      message: defaultMessage,
      permanent: true,
    });
    return;
  }

  let message = '';
  let errorMessages = '';

  if (typeof e.response.data === 'string') {
    message = e.response.data;
  } else if (e.response.data.message) {
    message = e.response.data.message;
  } else {
    message = defaultMessage || e.response.statusText;
  }

  if (e.response.data.errors && showAdditionalError) {
    const { errors } = e.response.data;
    for (const key of Object.keys(errors)) {
      errorMessages += `, ${key}: `;
      if (errors instanceof Map) {
        errorMessages += errors.get(key);
      } else {
        errorMessages += errors[key];
      }
    }
  }

  const finalMessage = message + errorMessages;

  Bus.emit(NOTIFICATION.ERROR, {
    message: finalMessage,
    permanent: true,
  });
}

export function identifyDeviceType(): IDevice {
  let isMobile = false;
  if (
    /Android/i.test(navigator.userAgent)
    && /Mobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  } else if (/webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  } else if (window.innerWidth < 768) {
    isMobile = true;
  }

  const isTouchDevice = 'ontouchend' in document;
  const isChrome = /Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent);
  const isSafari = /Safari/i.test(navigator.userAgent) && !isChrome;
  const isFirefox = /Firefox/i.test(navigator.userAgent);
  const isMacintosh = /Macintosh/i.test(navigator.userAgent);
  const isMac = isMacintosh && !isTouchDevice;
  const isWindows = /Windows/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isAndroidTablet = /Android/i.test(navigator.userAgent)
      && !/Mobile/i.test(navigator.userAgent);
  const isLinux = /Linux/i.test(navigator.userAgent) && !isAndroid;
  const isIPad = /iPad/i.test(navigator.userAgent) || (isMacintosh && isTouchDevice);
  const isIPhone = /iPhone/i.test(navigator.userAgent);

  return {
    isMobile,
    isTouchDevice,
    isChrome,
    isSafari,
    isFirefox,
    isMac,
    isWindows,
    isAndroid,
    isLinux,
    isIPad,
    isIPhone,
    isAndroidTablet,
  };
}

export async function fetchAsBlob(url: string): Promise<Blob> {
  const res = await fetch(url);
  return res.blob();
}

export async function download(content: string, filename: string): Promise<void> {
  Bus.emit(NOTIFICATION.SUCCESS, {
    message: `${filename} ফাইলটি ডাউনলোড হচ্ছে`,
  });

  try {
    const element = document.createElement('a');

    if (content.startsWith('http')) {
      const blob = await fetchAsBlob(content);
      const link = window.URL.createObjectURL(blob);
      element.setAttribute('href', link);
    } else {
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
    }

    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  } catch (e: unknown) {
    console.error('Error in download', e);

    Bus.emit(NOTIFICATION.ERROR, {
      message: `${filename} ফাইল ডাউনলোডে সমস্যা হয়েছে`,
    });
  }
}

export async function copyToClipboard(text: string): Promise<void> {
  const _legacyCopy = (): void => {
    const input: HTMLInputElement = window.document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.focus();
    input.select();

    document.execCommand('copy');
    document.body.removeChild(input);
  };

  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    _legacyCopy();
  }
}

export function getSearchParamsFromUrl(
  url: string,
): ISearchParams {
  const { searchParams } = new URL(decodeURI(url), window.location.origin);

  const reference = searchParams.get('reference') || undefined;
  const redirect = searchParams.get('redirect') || undefined;

  return {
    reference, redirect,
  };
}

export async function redirectUserAfterLogin(
  user: IUser,
  redirectUrl = '',
): Promise<{ route: string }> {
  const handleFirstLoginRedirect = (): { route: string } => {
    let route = CONSTANTS.ROUTE.DEFINED.ACCOUNT_EDIT_PAGE;
    if (redirectUrl) {
      route += `?redirect=${
        encodeURIComponent(redirectUrl)
      }`;
    }
    return {
      route,
    };
  };

  if (!user.fbLink) {
    return handleFirstLoginRedirect();
  }

  // IF it is end-user BUT
  // there is no `redirectUrl` OR `redirectUrl` is not an end-user url
  // THEN route to appropriate end-user url
  if (!redirectUrl) {
    return {
      route: CONSTANTS.ROUTE.DEFINED.MEMBER_HOME,
    };
  }

  // otherwise go to redirect url
  return {
    route: redirectUrl,
  };
}

export function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);

  const base64 = (base64String + padding)
    // eslint-disable-next-line
      .replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function trackGoogleTagEvent(
  event: string,
  action: string,
  value: string,
): void {
  const gtm = useGtm();
  if (!gtm) {
    console.error('gtm is undefined');
    return;
  }
  gtm.trackEvent({
    event,
    action,
    value,
  });
}

export function getFileSignedUrl(
  { fileName, method, size, isPublic }: {
    fileName: string;
    method: 'get' | 'put';
    size?: number;
    isPublic?: boolean
  },
): string {
  let fullPath;
  if (fileName.includes('/')) {
    // Split and encode only if there's a directory path
    const pathParts = fileName.split('/');
    const encodedFileName = encodeURIComponent(pathParts.pop() || '');
    const directoryPath = pathParts.join('/');
    fullPath = `${directoryPath}/${encodedFileName}`;
  } else {
    // Encode the file name directly if there's no directory
    fullPath = encodeURIComponent(fileName);
  }

  // Construct the URL parameters
  const params = new URLSearchParams({
    fileName: fullPath,
    method,
    ...(size !== undefined && {
      size: size.toString(),
    }),
    ...(isPublic && {
      isPublic: '1',
    }),
  });

  const url = `/files/file-signed-url?${params.toString()}`;
  return url;
}

export async function uploadFile(
  file: File,
  filePath: string,
  updateProgress: (progress: number) => void,
) : Promise<string> {
  try {
    const url = getFileSignedUrl({
      fileName: filePath,
      method: 'put',
      size: file.size,
    });

    const signedUrlResp = await axios.get<{ signedUrl: string }>(url);
    const { signedUrl } = signedUrlResp.data;

    const axiosInstance = axios.create();
    delete axiosInstance.defaults.headers.common.Authorization;

    const config: AxiosRequestConfig = {
      onUploadProgress: (e: AxiosProgressEvent) => {
        const progress = (e.loaded / (e.total || 1)) * 100;
        updateProgress(progress);
      },
    };

    await axiosInstance.put(signedUrl, file, config);

    return filePath;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

export function convertToBengaliDate(
  dateString: string,
  withTime = false,
): string {
  const bengaliNumbers = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
  const englishToBengaliMonth = {
    January: 'জানুয়ারী',
    February: 'ফেব্রুয়ারী',
    March: 'মার্চ',
    April: 'এপ্রিল',
    May: 'মে',
    June: 'জুন',
    July: 'জুলাই',
    August: 'আগস্ট',
    September: 'সেপ্টেম্বর',
    October: 'অক্টোবর',
    November: 'নভেম্বর',
    December: 'ডিসেম্বর',
  };

  const date = new Date(dateString);
  dayjs.locale('bn');

  let formattedDate = dayjs(date).format(`D MMMM, YYYY${withTime ? ', hh:mm A' : ''} `);

  formattedDate = formattedDate.replace(
    /\d/g,
    (match) => bengaliNumbers[parseInt(match, 10)],
  );

  Object.entries(englishToBengaliMonth).forEach(([english, bengali]) => {
    formattedDate = formattedDate.replace(english, bengali);
  });

  return formattedDate;
}

export function formatPriceToBengali(price: number): string {
  const bengaliNumbers = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
  return price.toString().split('').map((digit) => bengaliNumbers[parseInt(digit, 10)]).join('');
}

export function shouldProcessWindowPostMessage(event: MessageEvent): boolean {
  const trustedOrigins = [
    'https://dev.admin.ft.education', 'https://admin.ft.education',
    'https://localhost:8081', 'http://localhost:8081',
    'http://127.0.0.1:8081', 'https://127.0.0.1:8081',
  ];

  if (!trustedOrigins.includes(event.origin)) {
    console.error('Received message from an unauthorized origin:', event.origin);
    return false;
  }
  // Validate the structure of the received message data.
  if (!event.data || typeof event.data !== 'object') {
    console.error('Invalid or unexpected message data received');
    return false;
  }

  return true;
}

export function getDurationInBangla(duration: number, isMilliSecond = false): string {
  let durationInMinute = duration;

  if (isMilliSecond) {
    const totalSeconds = Math.floor(duration / 1000);
    durationInMinute = Math.ceil(totalSeconds / 60);
  }

  const hour = Math.floor((durationInMinute / 60));
  const minute = durationInMinute % 60;

  let text = `${minute.toLocaleString('bn')} মিনিট `;

  if (hour) {
    text = `${hour.toLocaleString('bn')} ঘন্টা ${text}`;
  }

  return text;
}

export function getNumberInBangla(value: number, decimal = 2): string {
  const bn = value.toLocaleString('bn');

  if (Number.isInteger(value)) {
    return bn;
  }

  const scoreParts = bn.split('.');
  const decimalPart = scoreParts[1].slice(0, decimal);

  return `${scoreParts[0]}.${decimalPart.padEnd(decimal, '০')}`;
}
