/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Aug 05 2022
 */

import axios from 'axios';
import type {
  GetSubjectsDto,
} from 'dto';
import {
  defineStore,
} from 'pinia';
import {
  coursePrograms,
} from 'utilities';
import {
  handleAxiosError,
} from '@/helpers';
import {
  ChangeTypeOfKeys,
  IClientSettings,
  IDynamicPage,
  IEnrollment,
  IHomeBanner,
  ISubject,
} from '@/types';

export const useBackendStore = defineStore('backend', {
  state: () => ({
    isTesting: undefined as string | undefined,
    enrolledCourses: [] as string[],
    homeBanners: [] as IHomeBanner[],
    subjectsForProgram: {
    } as Record<string, ISubject[]>,
    dynamicPages: [] as IDynamicPage[],
  }),
  actions: {
    async initOnAppMount(): Promise<void> {
      const homeBannerPromise = this.fetchHomeBanners();
      const dynamicPagesPromise = this.fetchDynamicPages();
      const subjectPromises = this.fetchSubjectsForProgram();

      await Promise.all([
        homeBannerPromise,
        dynamicPagesPromise,
        ...subjectPromises,
      ]);
    },
    async initOnAuthChange(token: string): Promise<void> {
      const enrolledCoursePromise = async (): Promise<void> => {
        if (!token) {
          this.enrolledCourses = [];
          return;
        }

        try {
          const response = await axios.get<{docs : IEnrollment[]}>('/enrollments?fields=course');
          this.enrolledCourses = response.data.docs.map((em) => em.course as string);
        } catch (e: any) {
          handleAxiosError(e);
        }
      };

      await Promise.all([enrolledCoursePromise()]);
    },
    fetchSubjectsForProgram(): Promise<void>[] {
      const subjectsForProgramPromise = async (program: string): Promise<void> => {
        try {
          type FetchDocsParams = ChangeTypeOfKeys<GetSubjectsDto, 'filterBy' | 'fields', string | undefined>;

          const params: FetchDocsParams = {
            fields: '_id,title,program,chapters',
            search: program,
            filterBy: 'program',
          };

          const response = await axios.get<{
            docs: ISubject[]; totalData: number;
          }>('/subjects', {
            params,
          });

          this.subjectsForProgram[program] = response.data.docs;
        } catch (e: any) {
          const errMessage = `Error in getting subjects for program ${program}`;
          handleAxiosError(e, errMessage);
        }
      };

      return coursePrograms.map((p) => subjectsForProgramPromise(p));
    },
    async fetchClientSettings(): Promise<void> {
      try {
        const response = await axios.get<{ settings: IClientSettings }>('/settings/client');
        this.isTesting = response.data.settings.isTesting;
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async fetchHomeBanners(): Promise<void> {
      try {
        const response = await axios.get<{
          docs: IHomeBanner[], totalData: number
        }>('/home-banners?fields=_id,altText,imgSrcDesktop,imgSrcNonDesktop,link');

        this.homeBanners = response.data.docs;
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async fetchDynamicPages(): Promise<void> {
      try {
        const response = await axios.get<{
          docs: IDynamicPage[], totalData: number}
        >('/dynamic-pages?fields=_id,title,slug,courses');

        this.dynamicPages = response.data.docs;
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
