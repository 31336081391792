/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Jan 03 2023
 */

<template>
  <div class="flex justify-center">
    <span class="my-auto text-xs font-bold xs:text-sm lg:text-base">
      Developed by
    </span>
    <a
      class="pl-4"
      href="https://nerddevs.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        :src="require('@/assets/site-assets/nerddevs_orange_logo.png')"
        alt="nerddevs-logo"
        class="h-5 w-24 sm:h-[26px] sm:w-[132px]"
      >
    </a>
  </div>
</template>
