/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import axios, {
  AxiosResponse,
} from 'axios';
import {
  defineStore,
} from 'pinia';
import {
  CONSTANTS,
  abbreviateName,
  handleAxiosError,
  profileImageSrc,
} from '@/helpers';
import {
  IFacebookLoginInfo,
  IGoogleLoginInfo,
  ILoginInfo,
  ILoginType,
  IUser,
} from '@/types';

interface ILoginStorageData {
  token: string;
  loginType: ILoginType;
}

interface ISetAuthInfoParams extends ILoginStorageData {
  user?: IUser;
}

async function getLoggedInUser(token: string): Promise<IUser | undefined> {
  try {
    const resp = await axios.get('/users/this', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data.user;
  } catch (e: any) {
    console.error(e);
    return undefined;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: '',
    authCardTitle: '',
    loginType: '' as ILoginType,
    user: undefined as IUser | undefined,
  }),
  getters: {
    usernameAbbreviation: (state) => abbreviateName(
      state.user?.firstName || state.user?.username,
      state.user?.lastName,
    ),
    userProfileImageSrc: async (state) => {
      const profileImage = await profileImageSrc(state.user);
      return profileImage;
    },
  },
  actions: {
    async refreshUser() {
      const user = await getLoggedInUser(this.token);
      this.user = user;
    },
    setAuthInfo({ loginType, token, user }: ISetAuthInfoParams) {
      this.loginType = loginType;
      this.user = user;
      this.token = token;

      const storage = window.localStorage.getItem(CONSTANTS.STORAGE.TABBED_REMEMBER_ME)
        ? window.sessionStorage : window.localStorage;

      const loginStorageData: ILoginStorageData = {
        loginType,
        token,
      };

      storage.setItem(
        CONSTANTS.STORAGE.LOGIN_DATA,
        JSON.stringify(loginStorageData),
      );
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
    async login(loginType: ILoginType, token: string) {
      const user = await getLoggedInUser(token);
      this.setAuthInfo({
        loginType, token, user,
      });
    },
    async localLogin() {
      const storage = window.localStorage.getItem(CONSTANTS.STORAGE.TABBED_REMEMBER_ME)
        ? window.sessionStorage : window.localStorage;

      const stringifiedLoginData = storage.getItem(CONSTANTS.STORAGE.LOGIN_DATA);

      if (!stringifiedLoginData) {
        return;
      }

      const { token, loginType } = JSON.parse(stringifiedLoginData) as ILoginStorageData;

      if (!token) {
        return;
      }

      const user = await getLoggedInUser(token);
      this.setAuthInfo({
        loginType: loginType as ILoginType, token, user,
      });
    },
    async googleLogin({
      code,
      credential,
      referredCode,
    }: IGoogleLoginInfo) {
      try {
        let response: AxiosResponse<ILoginInfo>;

        if (credential) {
          response = await axios.post<ILoginInfo>(
            '/web/auth/login/google/one-tap',
            {
              credential,
              referredCode,
            },
          );
        } else {
          response = await axios.post<ILoginInfo>(
            '/web/auth/login/google/oauth2',
            {
              code,
              referredCode,
            },
          );
        }

        const { user, token } = response.data;
        this.setAuthInfo({
          loginType: 'google', token, user,
        });
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async fbLogin({
      token: fbToken,
      id,
      email,
      firstName,
      lastName,
      profileImage,
      referredCode,
    }: IFacebookLoginInfo) {
      try {
        const response = await axios.post<ILoginInfo>('/web/auth/login/fb', {
          token: fbToken,
          id,
          email,
          firstName,
          lastName,
          profileImage,
          referredCode,
        });
        const { user, token } = response.data;
        this.setAuthInfo({
          loginType: 'fb', token, user,
        });
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async logout(isUnauthorized?: boolean): Promise<boolean> {
      if (isUnauthorized) {
        this.setAuthInfo({
          loginType: '', token: '',
        });
        return true;
      }

      try {
        await axios.delete('/web/auth/logout');
        this.setAuthInfo({
          loginType: '', token: '',
        });
        return true;
      } catch (e: any) {
        handleAxiosError(e);
        return false;
      }
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
