/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Dec 05 2024
 */

// REFERENCE: https://router.vuejs.org/guide/advanced/lazy-loading.html
/**
 * NOTE: For every new webpackChunkName, that chunk name should be added to `LazyScript.vue` file
 */

// Common Home (Public only)
export const LandingPage = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "home" */
  '@/views/Common/LandingPage.vue'
);

// Common Utilities
export const PrivacyPolicy = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "privacy-policy" */
  '@/views/Common/PrivacyPolicy.vue'
);
export const RefundPolicy = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "refund-policy" */
  '@/views/Common/RefundPolicy.vue'
);
export const TermsOfService = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "terms-of-service" */
  '@/views/Common/TermsOfService.vue'
);
export const Faq = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "faq" */
  '@/views/Common/FaqSection.vue'
);
export const AboutUs = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "about-us" */
  '@/views/Common/AboutUs.vue'
);
export const NewsView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "news" */
  '@/views/Common/NewsView.vue'
);

// Common Courses
export const OurCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "our-courses" */
  '@/views/Common/Course/OurCourses.vue'
);
export const FreeCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "free-courses" */
  '@/views/Common/Course/FreeCourses.vue'
);
export const DynamicPagesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "dynamic-pages" */
  '@/views/Common/Course/DynamicPages.vue'
);
export const CourseDetailsView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-details" */
  '@/views/Common/Course/CourseDetails.vue'
);

// Auth
export const AuthView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/AuthView.vue'
);
export const LoginView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/LoginView.vue'
);
export const SignupView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/SignupView.vue'
);
export const ResetPassword = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/ResetPassword.vue'
);
export const VerifyResetPassword = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/VerifyResetPassword.vue'
);
export const ActivateEmail = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/ActivateEmail.vue'
);
export const VerifyEmail = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "auth" */
  '@/views/Auth/VerifyEmail.vue'
);
export const GoogleLogin = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "google-login" */
  '@/views/Auth/GoogleLogin.vue'
);

// End User Utilities
export const ContactView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-utilities-view" */
  '@/views/EndUser/ContactView.vue'
);

// End User Courses
export const DashboardView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-dashboard-view" */
  '@/views/EndUser/DashboardView.vue'
);
export const EnrolledCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-enrolled-courses" */
  '@/views/EndUser/Course/EnrolledCourses.vue'
);
export const RunningCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-running-courses" */
  '@/views/EndUser/Course/RunningCourses.vue'
);
export const UpcomingCoursesView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-upcoming-courses" */
  '@/views/EndUser/Course/UpcomingCourses.vue'
);

// End User Contents
export const CourseMaterialsView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "course-materials" */
  '@/views/EndUser/Content/CourseMaterials.vue'
);
export const UserExamView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-exam" */
  '@/views/EndUser/Content/UserExam.vue'
);
export const UserResultView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-result" */
  '@/views/EndUser/Content/UserResult.vue'
);
export const LeaderBoardView = (): Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "leader-board" */
  '@/views/EndUser/Content/LeaderBoard.vue'
);

// End User Account
export const UserView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/UserView.vue'
);
export const AccountView = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/AccountView.vue'
);
export const AccountEdit = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/AccountEdit/AccountEdit.vue'
);
export const EditProfile = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/AccountEdit/EditProfile.vue'
);
export const EditGuardian = ():Promise<typeof import('*.vue')> => import(
  /* webpackChunkName: "user-account-view" */
  '@/views/EndUser/AccountEdit/EditGuardian.vue'
);
