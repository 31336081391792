/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Dec 05 2024
 */

import {
  RouteRecordRaw,
} from 'vue-router';
import {
  AboutUs,
  AccountEdit,
  AccountView,
  ActivateEmail,
  AuthView,
  ContactView,
  CourseDetailsView,
  CourseMaterialsView,
  DashboardView,
  DynamicPagesView,
  EditGuardian,
  EditProfile,
  EnrolledCoursesView,
  Faq,
  FreeCoursesView,
  GoogleLogin,
  LandingPage,
  LeaderBoardView,
  LoginView,
  NewsView,
  OurCoursesView,
  PrivacyPolicy,
  RefundPolicy,
  ResetPassword,
  RunningCoursesView,
  SignupView,
  TermsOfService,
  UpcomingCoursesView,
  UserExamView,
  UserResultView,
  UserView,
  VerifyEmail,
  VerifyResetPassword,
} from './component';
import {
  CONSTANTS,
} from '@/helpers';

const isGuest = {
  meta: {
    isGuest: true,
  },
};

const isCommon = {
  meta: {
    isCommon: true,
  },
};

export const routes: Array<RouteRecordRaw> = [
  {
    path: CONSTANTS.ROUTE.home.path,
    name: CONSTANTS.ROUTE.home.title,
    component: LandingPage,
    meta: {
      title: CONSTANTS.ROUTE.home.title,
      alternateTitle: CONSTANTS.ROUTE.home.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthView,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: {
          title: 'Login',
          alternateTitle: 'লগ ইন করো',
          ...isGuest.meta,
        },
      },
      {
        path: 'signup',
        name: 'SignUp',
        component: SignupView,
        meta: {
          title: 'Sign Up',
          alternateTitle: 'সাইন আপ করো',
          ...isGuest.meta,
        },
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          title: 'Reset Password',
          alternateTitle: 'রিসেট পাসওয়ার্ড',
          ...isGuest.meta,
        },
      },
      {
        path: 'verify-reset-password/:id',
        name: 'VerifyResetPassword',
        component: VerifyResetPassword,
        meta: {
          title: 'Verify Reset Password',
          ...isGuest.meta,
        },
      },
      {
        path: 'activate-email',
        name: 'ActivateEmail',
        component: ActivateEmail,
        meta: {
          title: 'Activate Email',
          alternateTitle: 'এক্টিভেট ইমেইল',
          ...isGuest.meta,
        },
      },
      {
        path: 'verify-email/:token',
        name: 'VerifyEmail',
        component: VerifyEmail,
        meta: {
          title: 'Verify Email',
          alternateTitle: 'ভেরিফাই ইমেইল',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/login/google',
    name: 'GoogleLogin',
    component: GoogleLogin,
    meta: {
      title: 'Google Login',
      ...isGuest.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.privacyPolicy.path,
    name: CONSTANTS.ROUTE.privacyPolicy.title,
    component: PrivacyPolicy,
    meta: {
      title: CONSTANTS.ROUTE.privacyPolicy.title,
      alternateTitle: CONSTANTS.ROUTE.privacyPolicy.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.refundPolicy.path,
    name: CONSTANTS.ROUTE.refundPolicy.title,
    component: RefundPolicy,
    meta: {
      title: CONSTANTS.ROUTE.refundPolicy.title,
      alternateTitle: CONSTANTS.ROUTE.refundPolicy.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.termsOfService.path,
    name: CONSTANTS.ROUTE.termsOfService.title,
    component: TermsOfService,
    meta: {
      title: CONSTANTS.ROUTE.termsOfService.title,
      alternateTitle: CONSTANTS.ROUTE.termsOfService.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.faq.path,
    name: CONSTANTS.ROUTE.faq.title,
    component: Faq,
    meta: {
      title: CONSTANTS.ROUTE.faq.title,
      alternateTitle: CONSTANTS.ROUTE.faq.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.aboutUs.path,
    name: CONSTANTS.ROUTE.aboutUs.title,
    component: AboutUs,
    meta: {
      title: CONSTANTS.ROUTE.aboutUs.title,
      alternateTitle: CONSTANTS.ROUTE.aboutUs.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.news.path,
    name: CONSTANTS.ROUTE.news.title,
    component: NewsView,
    meta: {
      title: CONSTANTS.ROUTE.news.title,
      alternateTitle: CONSTANTS.ROUTE.news.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.userContact.path,
    name: CONSTANTS.ROUTE.userContact.title,
    component: ContactView,
    meta: {
      title: CONSTANTS.ROUTE.userContact.title,
      alternateTitle: CONSTANTS.ROUTE.userContact.alternateTitle,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: {
      title: 'Dashboard',
      alternateTitle: 'ড্যাশবোর্ড',
    },
  },
  {
    path: '/materials/course/:courseId',
    props: (route) => ({
      courseId: route.params.courseId,
    }),
    name: 'CourseMaterials',
    component: CourseMaterialsView,
    meta: {
      title: 'Course Materials',
      alternateTitle: 'কোর্স ম্যাটেরিয়ালস',
    },
  },
  {
    path: '/user-exam/:examId',
    props: (route) => ({
      examId: route.params.examId,
      showPreview: route.query.showPreview,
      isLastAttempt: route.query.last,
    }),
    name: 'UserExam',
    component: UserExamView,
    meta: {
      title: 'User Exam',
      alternateTitle: 'শিক্ষার্থী এক্সাম',
    },
  },
  {
    path: '/user-result/:examId',
    props: (route) => ({
      examId: route.params.examId,
    }),
    name: 'UserResult',
    component: UserResultView,
    meta: {
      title: 'User Result',
      alternateTitle: 'শিক্ষার্থী এক্সাম ফলাফল',
    },
  },
  {
    path: '/leader-board/:quizId',
    props: (route) => ({
      quizId: route.params.quizId,
    }),
    name: 'LeaderBoard',
    component: LeaderBoardView,
    meta: {
      title: 'Leader Board',
      alternateTitle: 'লিডারবোর্ড',
    },
  },
  {
    path: CONSTANTS.ROUTE.myEnrolledCourses.path,
    name: CONSTANTS.ROUTE.myEnrolledCourses.title,
    props: (route) => ({
      notification: route.query.notification,
    }),
    component: EnrolledCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.myEnrolledCourses.title,
      alternateTitle: CONSTANTS.ROUTE.home.alternateTitle,
    },
  },
  {
    path: CONSTANTS.ROUTE.myUpcomingCourses.path,
    name: CONSTANTS.ROUTE.myUpcomingCourses.title,
    component: UpcomingCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.myUpcomingCourses.title,
      alternateTitle: CONSTANTS.ROUTE.myUpcomingCourses.alternateTitle,
    },
  },
  {
    path: CONSTANTS.ROUTE.myRunningCourses.path,
    name: CONSTANTS.ROUTE.myRunningCourses.title,
    component: RunningCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.myRunningCourses.title,
      alternateTitle: CONSTANTS.ROUTE.myRunningCourses.alternateTitle,
    },
  },
  {
    path: '/our-courses/:filter',
    name: CONSTANTS.ROUTE.allOurCourses.title,
    component: OurCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.allOurCourses.title,
      alternateTitle: CONSTANTS.ROUTE.allOurCourses.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: CONSTANTS.ROUTE.freeCourses.path,
    name: CONSTANTS.ROUTE.freeCourses.title,
    component: FreeCoursesView,
    meta: {
      title: CONSTANTS.ROUTE.freeCourses.title,
      alternateTitle: CONSTANTS.ROUTE.freeCourses.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: `${CONSTANTS.ROUTE.dynamicPages.path}/:slug`,
    props: (route) => ({
      slug: route.params.slug,
    }),
    name: CONSTANTS.ROUTE.dynamicPages.title,
    component: DynamicPagesView,
    meta: {
      title: CONSTANTS.ROUTE.dynamicPages.title,
      alternateTitle: CONSTANTS.ROUTE.dynamicPages.alternateTitle,
      ...isCommon.meta,
    },
  },
  {
    path: '/course/:slug',
    props: (route) => ({
      slug: route.params.slug,
      notification: route.query.notification,
      showPreview: route.query.showPreview,
    }),
    name: 'CourseDetails',
    component: CourseDetailsView,
    meta: {
      title: 'Course Details',
      alternateTitle: 'কোর্স ডিটেইলস',
      ...isCommon.meta,
    },
  },
  {
    path: '/user',
    name: 'User',
    component: UserView,
    children: [
      {
        path: 'account/view',
        name: CONSTANTS.ROUTE.myAccount.title,
        component: AccountView,
        meta: {
          title: CONSTANTS.ROUTE.myAccount.title,
          alternateTitle: CONSTANTS.ROUTE.myAccount.alternateTitle,
        },
      },
      {
        path: 'account/edit',
        name: 'AccountEdit',
        component: AccountEdit,
        children: [
          {
            path: 'profile',
            name: 'EditProfile',
            component: EditProfile,
            meta: {
              title: 'Edit Profile',
              alternateTitle: 'এডিট প্রোফাইল',
            },
          },
          {
            path: 'guardian',
            name: 'EditGuardian',
            component: EditGuardian,
            meta: {
              title: 'Edit Guardian',
              alternateTitle: 'এডিট অভিভাবক',
            },
          },
        ],
      },
    ],
  },
];
