import {
  createGtm,
} from '@gtm-support/vue-gtm';
import axios, {
  AxiosError,
} from 'axios';
import {
  createPinia,
} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {
  unAuthorizeAccessError,
} from 'utilities';
import {
  createApp,
} from 'vue';
import App from '@/App.vue';
import {
  CONFIG,
  IS_DEVELOPMENT,
  ROUTE_API,
  baseUrl,
} from '@/helpers';
import {
  UtilityCommands,
} from '@/plugins/utility';
import router from '@/router';
import {
  registerStore,
  resetStore,
  resetUserSpecificStore,
  useStore,
} from '@/store';
import '@/style/css/index.css';
import '@/style/scss/index.scss';

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.config.errorHandler = (err, vm, info): void => {
  console.error(`Error in ${info}:`, err);

  const authStore = useStore.auth;

  const username = authStore.user?.username || 'NA';

  const data = {
    message: `[${username}] ${(err as Error).message}`,
    info,
    stack: (err as Error).stack,
  };
  axios.post('/errors', data)
    .catch(console.error);
};

axios.defaults.baseURL = `${baseUrl}${ROUTE_API}`;
axios.defaults.timeout = 60 * 1000;

axios.interceptors.response.use(undefined, async (error) => {
  const errResponse = (error as AxiosError<{message: string}>).response;
  const authStore = useStore.auth;

  if (errResponse?.status === 401
    && errResponse?.data.message === unAuthorizeAccessError
  ) {
    const isLoggedOut = await authStore.logout(true);

    if (isLoggedOut) {
      resetUserSpecificStore();
    }

    if (isLoggedOut && router.currentRoute.value.fullPath !== '/') {
      router.push('/');
    }
  }

  return Promise.reject(error);
});

app.use(router);
app.use(UtilityCommands);

app.use(
  createGtm({
    id: CONFIG.googleTagManagerId,
    // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    compatibility: false,
    debug: IS_DEVELOPMENT, // Whether or not display console logs debugs (optional)
    // Whether or not to load the GTM Script (Helpful if you are including GTM
    // manually, but need the dataLayer functionality in your components) (optional)
    loadScript: true,
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }),
);

resetStore();
registerStore();

app.mount('#app');
